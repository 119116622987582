<header class="hero overflow-hidden">

  <div class="container-fluid">

    <div class="row">
      <owl-carousel-o class="col-12" [options]="customOptions" #pHero>
        <ng-template carouselSlide>
          <div class="row">
            <div class="col-12">
              <a href="https://iccn.or.id" target="_blank">
                <img class="hero__img" src="assets/img/banner/hero_des_2024_1.jpg" alt="">
              </a>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="row">
            <div class="col-12">
              <a href="https://iccn.or.id" target="_blank">
                <img class="hero__img" src="assets/img/banner/hero_des_2024_2.jpg" alt="">
              </a>
            </div>
          </div>
        </ng-template>
        <!-- <ng-template carouselSlide>
          <div class="row align-items-center">
            <div class="col-lg-6 mb-5 mb-lg-0">
              <div class="hero__text">
                <div class="hero__text-wrapper">
                  <h1 class="hero__text-big">
                    Padamu Negeri Kami Berkolaborasi
                  </h1>
                  <h4 class="hero__text-small">
                    Selamat datang di Indonesia Creative Cities Network
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <img src="assets/img/banner/banner_slider1.png" class="hero__img">
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="row align-items-center">
            <div class="col-lg-6 mb-5 mb-lg-0">
              <div class="hero__text">
                <div class="hero__text-wrapper">
                  <h1 class="hero__text-big">
                    ABBS
                  </h1>
                  <h4 class="hero__text-small">
                    Aksi Bersama Bantu Sesama
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <img src="assets/img/banner/banner_slider2.png" class="hero__img">
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="row align-items-center">
            <div class="col-lg-6 mb-5 mb-lg-0">
              <div class="hero__text">
                <div class="hero__text-wrapper">
                  <h1 class="hero__text-big">
                    Labuan Bajo
                  </h1>
                  <h4 class="hero__text-small">
                    Indonesia Creative Cities Festival 2022
                  </h4>
                  <a class="btn btn-iccn btn-lg rounded-pill mt-3" href="mailto:creativecitiesid@gmail.com" target="_blank">
                    Informasi
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <img src="assets/img/banner/banner_slider3.png" class="hero__img">
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="row align-items-center">
            <div class="col-lg-6 mb-5 mb-lg-0">
              <div class="hero__text">
                <div class="hero__text-wrapper">
                  <h1 class="hero__text-big">
                    Rakornas ICCN 2022 di Jakarta
                  </h1>
                  <h4 class="hero__text-small">
                    Tengah tahun 2022
                  </h4>
                  <a class="btn btn-iccn btn-lg rounded-pill mt-3" href="mailto:creativecitiesid@gmail.com" target="_blank">
                    Informasi
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <img src="assets/img/banner/banner_slider4.png" class="hero__img">
            </div>
          </div>
        </ng-template> -->
        <!-- <ng-template carouselSlide>
          <div class="row align-items-center">
            <div class="col-lg-12">
              <img src="assets/img/banner/banner_3.png" class="hero__img d-none d-md-block">
              <img src="assets/img/banner/banner_3_mobile.png" class="hero__img d-block d-md-none">
            </div>
          </div>
        </ng-template> -->
      </owl-carousel-o>
    </div>

    <!-- <button class="btn hero__btn hero__btn-left btn-outline-iccn-alt rounded-circle" (click)="pHero.prev()">
      <i class="fas fa-fw fa-arrow-left"></i>
    </button>
    <button class="btn hero__btn hero__btn-right btn-outline-iccn-alt rounded-circle" (click)="pHero.next()">
      <i class="fas fa-fw fa-arrow-right"></i>
    </button> -->

  </div>

</header>
